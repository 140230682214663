import { ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from "@angular/core";
import { Masquerade, MasqueradeService } from "../../services/masquerade.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Table } from "../s25-table/Table";
import { CacheRepository } from "../../decorators/cache.decorator";
import { Bind } from "../../decorators/bind.decorator";
import { GenericTableButtonComponent } from "../s25-table/generics/generic.table.button.component";
import { ModalService } from "../modal/modal.service";
import { S25TableComponent } from "../s25-table/s25.table.component";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { UserprefService } from "../../services/userpref.service";

@TypeManagerDecorator("s25-ng-masquerade-list")
@Component({
    selector: "s25-ng-masquerade-list",
    template: `
        <div class="c-margin-bottom--half">
            <button class="aw-button aw-button--primary" (click)="onCreateClick()">Add Masquerade</button>
            &nbsp; &nbsp;
            <button
                class="aw-button aw-button--primary"
                (click)="onDelete()"
                [disabled]="this.selectedItems.length === 0"
            >
                Delete
            </button>
            &nbsp; &nbsp;
            <button class="aw-button aw-button--primary" (click)="onBulkEditClick()">Bulk Edit</button>
        </div>

        <!--
        <s25-generic-dropdown
          [(items)]="this.filterList"
          [searchEnabled]="true"
          [(chosen)]="chosenFilter"
          (chosenChange)="onChangeFilter($event)"
        ></s25-generic-dropdown>
        -->

        @if (isInit) {
            <div class="top">
                <s25-ng-table
                    [dataSource]="tableData"
                    [hasRefresh]="true"
                    [hasFilter]="true"
                    [columnSortable]="true"
                    [pivotThreshold]="800"
                    [hasSelect]="true"
                    [hasSelectAll]="true"
                    (selectedChange)="selectedChange($event)"
                ></s25-ng-table>
            </div>
        }
    `,
    styles: `
        .top {
            max-width: 1200px;
        }

        .header {
            display: flex;
            justify-content: space-between;
        }

        .header > button {
            margin-bottom: 1em;
        }
    `,
})
export class S25MasqueradeListComponent implements OnInit {
    isInit = false;
    tableData: Table.DataSource;
    masquerade: Masquerade;
    masquerades: any = new Map<number, Masquerade>();
    dateFormat: string;
    selectedItems: number[] = [];
    getList: Masquerade[] = [];

    @ViewChild(S25TableComponent) table: S25TableComponent;

    constructor(
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {}

    async ngOnInit() {
        this.dateFormat = await UserprefService.getS25Dateformat();
        this.tableData = this.getTableConfig();
        this.isInit = true;
        this.cd.detectChanges();
    }

    getTableConfig(): Table.DataSource {
        return {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "sourceFirstName",
                    header: "Masquerade By",
                },
                {
                    id: "targetFirstName",
                    header: "Masquerade As",
                },

                {
                    id: "groupName",
                    header: "Security Group",
                },
                {
                    id: "expirationDt",
                    header: "Expiration Date",
                },
                GenericTableButtonComponent.Column("Edit", this.onEditClick, "outline"),
                GenericTableButtonComponent.Column("Delete", this.onDeleteClick, "danger--outline"),
            ],
        };
    }

    @Bind
    async getRows(query: Table.UnpaginatedQuery): Promise<Table.DataSourceResponse> {
        if (query.forceRefresh) CacheRepository.invalidateByService("MasqueradeService");
        let masquerades = await MasqueradeService.getMasqueradeUsers();
        masquerades ? (masquerades = S25Util.array.forceArray(masquerades)) : (masquerades = []);
        this.getList = masquerades;
        for (let masquerade of masquerades) this.masquerades.set(masquerade.id, masquerade);
        return {
            rows: masquerades.map(this.mapToRow) || [],
        };
    }

    @Bind
    mapToRow(item: Masquerade): Table.Row {
        if (!item.sourceFirstName) item.sourceFirstName = "";
        if (!item.targetFirstName) item.targetFirstName = "";
        return {
            id: item.id,
            name: item.sourceFirstName,
            cells: {
                sourceFirstName: { text: item.sourceFirstName + " " + item.sourceLastName },
                targetFirstName: { text: item.targetFirstName + " " + item.targetLastName },
                groupName: { text: item.groupName },
                expirationDt: {
                    text:
                        item.expirationDt !== "1900-01-01T00:00:00"
                            ? S25Datefilter.transform(item.expirationDt, this.dateFormat)
                            : "No Expiration",
                },
            },
        };
    }

    @Bind
    async refresh(val: boolean) {
        this.selectedItems = [];
        val && (await this.table.refresh(true));
    }

    @Bind
    async onDeleteClick(row: Table.Row) {
        let dialogData = ModalService.dialogType(
            "Yes No",
            {
                message: "Are you sure you want to delete this masquerade?",
                title: "Confirm Deletion",
            },
            "No",
        );
        await ModalService.modal("dialog", dialogData);
        if (dialogData.answer !== 1) return; // User answered no
        //console.log({id:row.id, row: row });
        await MasqueradeService.deleteMasqueradeUser(row.id as number).catch(this.error);
        // this.table.selected.clear();
        return this.table.refresh();
    }

    @Bind
    onEditClick(row: Table.Row) {
        ModalService.modal("edit-masquerade", {
            masquerade: this.masquerades.get(row.id as number),
            bulkEdit: false,
            title: `Edit masquerade: ${row.name}`,
            onSave: this.table.refresh,
        });
    }

    @Bind
    onBulkEditClick() {
        let list: Masquerade[] = this.getList.filter((a: Masquerade) =>
            this.selectedItems.some((i: number) => a.id === i),
        );
        ModalService.modal("edit-masquerade", {
            masquerades: list,
            bulkEdit: true,
            title: `Bulk Edit`,
            onSave: this.table.refresh,
        });
    }

    onCreateClick() {
        ModalService.modal("edit-masquerade", {
            masquerade: {
                id: 0,
                sourceId: "",
                targetId: "",
                expirationDt: null,
            },
            bulkEdit: false,
            title: `Create Masquerade`,
            onSave: this.table.refresh,
        });
    }

    async onDelete() {
        let root: any = {};
        root.removedItems = { masqueradeId: this.selectedItems.toString() };
        await MasqueradeService.putMasqueradeUser(root, "masqueradeId");
        this.selectedItems = [];
        this.refresh(true);
        // this.table.selected.clear();
    }

    @Bind
    selectedChange(selectItem: any) {
        this.selectedItems = Array.from(selectItem);
        this.cd.detectChanges();
    }

    error(error: any) {
        S25Util.showError(error);
    }

    onChangeFilter(e: any) {
        //console.log({ e: e });
        // (this.table.filterValue, this.table.rows, [e.itemID])
    }
}
