import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { MasqueradeService } from "../../services/masquerade.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Bind } from "../../decorators/bind.decorator";
import { ModalService } from "../modal/modal.service";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { DropDownItem } from "../../pojo/DropDownItem";

@TypeManagerDecorator("s25-ng-masquerade-bulk-edit")
@Component({
    selector: "s25-ng-masquerade-bulk-edit",
    template: `
        @if (isInit) {
            <div class="masquerade-mapping">
                <s25-generic-dropdown
                    [(items)]="dropdownList"
                    [searchEnabled]="true"
                    [(chosen)]="chosenDropdownList"
                    (chosenChange)="onChangeChosen($event)"
                ></s25-generic-dropdown>
                @if (
                    chosenDropdownList.itemId === 1 ||
                    chosenDropdownList.itemId === 2 ||
                    chosenDropdownList.itemId === 3
                ) {
                    <div>
                        <div class="templateRow">
                            <label for="assignedBy " class="ngBold"
                                >Masquerade {{ chosenDropdownList.itemId === 2 ? "As" : "By" }}:</label
                            >
                            <s25-ng-multiselect-search-criteria
                                [type]="'contacts'"
                                [selectedItems]="multiSelectBean.selectedItems"
                                [modelBean]="multiSelectBean"
                                [popoverOnBody]="true"
                                [popoverPlacement]="'bottom-left top-left top left'"
                                [customFilterValue]="'&is_r25user=1'"
                            ></s25-ng-multiselect-search-criteria>
                        </div>
                    </div>
                }
                @if (chosenDropdownList.itemId === 3) {
                    <div>
                        <div class="templateRow">
                            <label for="assignedBy " class="ngBold">Masquerade As:</label>
                            <s25-ng-multiselect-search-criteria
                                [type]="'contacts'"
                                [selectedItems]="multiSelectBeanAs.selectedItems"
                                [modelBean]="multiSelectBeanAs"
                                [popoverOnBody]="true"
                                [popoverPlacement]="'bottom-left top-left top left'"
                                [customFilterValue]="'&is_r25user=1'"
                            ></s25-ng-multiselect-search-criteria>
                        </div>
                    </div>
                }
                @if (chosenDropdownList.itemId === 5) {
                    <div class="templateRow">
                        <label for="assignedBy" class="ngBold">Expiration Date: </label>
                        <s25-ng-editable-date
                            [(val)]="expirationDt"
                            (valChange)="onChange($event)"
                            [alwaysEditing]="true"
                        ></s25-ng-editable-date>
                    </div>
                }
                <div class="templateRow extraLength">
                    <div class="buttons c-margin-top--half">
                        <button
                            class="aw-button aw-button--primary c-margin-right--single"
                            (click)="onSave()"
                            [disabled]="!chosenDropdownList.itemId > 0"
                        >
                            Update
                        </button>
                        <button class="aw-button aw-button--outline" (click)="onCancel()">Cancel</button>
                    </div>
                </div>
            </div>
        }
    `,
    styles: `
        .top {
            max-width: 1200px;
        }

        .masquerade-mapping {
            max-width: 800px;
            min-height: 600px;
        }

        .templateRow {
            display: flex;
            gap: 1em;
            align-items: top;
            margin: 0.5em 0;
        }

        .templateRow > label:first-child {
            min-width: 10em;
            margin: 0;
        }

        .multiSelected {
            padding-left: 1em;
        }

        .templateRow > input {
            min-width: 15em;
        }

        .header {
            display: flex;
            justify-content: space-between;
        }

        .header > button {
            margin-bottom: 1em;
        }
    `,
})
export class S25MasqueradeBulkEditComponent implements OnInit {
    @Input() items?: any = [];

    isInit: boolean = false;
    masquerades: any;
    @Output() saved = new EventEmitter<{ id: number }>();
    @Output() cancelled = new EventEmitter<void>();
    @Output() modelValueChange = new EventEmitter();

    expirationDt: any;
    chosen: any;
    errorMsg = "";
    multiSelectBean: DropDownItem = {
        singleSelect: false,
        showResult: true,
    };
    multiSelectBeanAs: DropDownItem = {
        singleSelect: false,
        showResult: true,
    };

    dropdownList: DropDownItem = [
        { itemId: 1, itemName: "Delete All Masquerade By" },
        { itemId: 2, itemName: "Delete All Masquerade As" },
        { itemId: 3, itemName: "Delete Masquerade By and As" },
    ];

    chosenDropdownList: DropDownItem = { itemId: 0, itemName: "Select a Item" };

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {}

    async ngOnInit() {
        if (this.items && this.items.length > 0)
            this.dropdownList.push(
                //  { itemId: 4, itemName: "Delete Selected Items" },
                { itemId: 5, itemName: "Edit Expiration Date" },
            );
        this.isInit = true;
        this.cd.detectChanges();
    }

    editAssignedBy = () => {
        // console.log({ multiSelectBean: this.multiSelectBean });
    };

    onChangeChosen(e: any) {
        this.cd.detectChanges();
    }

    onChange(e: any) {
        // console.log({ e: e });
    }

    validate() {
        this.errorMsg = "";
        return true;
    }

    contactsLoaded = () => S25LoadingApi.destroy(this.elementRef.nativeElement);

    @Bind
    async onSave() {
        // delete by all selected items
        if (this.chosenDropdownList.itemId === 4) {
            this.onDeleteSelected();
        } else {
            if (!this.validate()) return;
            this.errorMsg = "";
            let ok: any;
            let root: any = {};
            let addedItems: any;
            let mode = "sourceId";
            //edit selected items expiration date
            if (this.chosenDropdownList.itemId === 5) {
                addedItems = [];
                if (!this.expirationDt || this.expirationDt === "undefined" || this.expirationDt === null) {
                    this.expirationDt = "1900-01-01 00:00:00";
                } else {
                    this.expirationDt = S25Datefilter.transform(this.expirationDt, "yyyy-MM-dd") + " 00:00:00";
                }
                for (let i = 0; i < this.items.length; i++) {
                    addedItems.push({
                        masqueradeId: this.items[i].id,
                        sourceId: this.items[i].sourceId,
                        targetId: this.items[i].targetId,
                        expirationDt: this.expirationDt,
                    });
                }
                if (addedItems && addedItems.length > 0) root.addedItems = addedItems;
            } else {
                let selectedItems = S25Util.array.propertyListToArray(
                    this.multiSelectBean.selectedItems,
                    true,
                    "itemId",
                );
                ////delete by all AS or By
                if (this.chosenDropdownList.itemId === 1 || this.chosenDropdownList.itemId === 2) {
                    if (this.chosenDropdownList.itemId === 2) mode = "targetId";
                    root.removedItems = { [mode]: selectedItems.toString() };
                } else if (this.chosenDropdownList.itemId === 3) {
                    ////delete selected AS AND  By
                    let selectedItemsAs = S25Util.array.propertyListToArray(
                        this.multiSelectBeanAs.selectedItems,
                        true,
                        "itemId",
                    );
                    mode = "";
                    root.removedItems = { sourceId: selectedItems.toString(), targetId: selectedItemsAs.toString() };
                }
            }
            ok = await MasqueradeService.putMasqueradeUser(root, mode);
            if (ok) this.saved.emit();
        }
    }

    onCancel() {
        this.cancelled.emit();
    }

    async onDeleteSelected() {
        let dialogData = ModalService.dialogType(
            "Yes No",
            {
                message: "Are you sure you want to delete?",
                title: "Confirm Deletion",
            },
            "No",
        );
        await ModalService.modal("dialog", dialogData);
        if (dialogData.answer !== 1) return; // User answered no
        let ok: any;
        let root: any = {};
        let selectedItems = S25Util.array.propertyListToArray(this.items, true, "id");
        root.removedItems = { masqueradeId: selectedItems.toString() };
        ok = await MasqueradeService.putMasqueradeUser(root, "masqueradeId");
        if (ok) this.saved.emit();
    }
}
