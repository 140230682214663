import { Injectable } from "@angular/core";

export const TYPE_MAP: any = {};

@Injectable({
    providedIn: "root",
})
export class TypeMapService {
    typeMap = TYPE_MAP;

    constructor() {}
}

export function TypeManagerDecorator(selector: string) {
    return function _TypeManagerDecorator<T extends { new (...args: any[]): any }>(constr: T) {
        TYPE_MAP[selector] = constr;
    };
}
