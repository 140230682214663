import { S25Util } from "../../util/s25-util";
import { S25Const } from "../../util/s25-const";
import { Item } from "../../pojo/Item";
import { SearchCriteria } from "../../pojo/SearchCriteria";
import Step = SearchCriteria.Step;

export class AdvancedSearchUtil {
    public static customAttrTypeMap: any = {
        B: { relationship_type_id: 1050 }, // this custom attr can be either true or false, but the id is the same regardless (50). s25AdvancedSearchCustomAttributes
        // therefore maps 1050 to true and 2050 to false to avoid duplicates. When writing to the DB, the 1000 is taken out
        I: { relationship_type_id: 57 },
        X: { relationship_type_id: 57 },
        2: { relationship_type_id: 50 },
        3: { relationship_type_id: 50 },
        4: { relationship_type_id: 50 },
        6: { relationship_type_id: 50 },
        D: { relationship_type_id: 50 },
        T: { relationship_type_id: 50 },
        E: { relationship_type_id: 50 },
        N: { relationship_type_id: 50 },
        F: { relationship_type_id: 50 },
        L: { relationship_type_id: 50 },
        R: { relationship_type_id: 58 },
        S: { relationship_type_id: 58 },
    };

    public static s25SearchAdvancedStepTemplate: any = {
        event: {
            10: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ favorites: "T" }],
                step_type_id: "10",
                step_type_name: "My favorite events",
            },
            100: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [
                    {
                        itemName: " ",
                        event_title: " ",
                        reference_number: " ",
                        event_structure_element_type: " ",
                        start_dt: new Date(),
                        end_dt: new Date(),
                        modified_since: new Date(),
                        last_modified_by: " ",
                        alien_uid: " ",
                        searchMethod: "is",
                    },
                ],
                step_type_id: "100",
                step_type_name: "General Event Details",
            },
            145: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ itemName: "" }],
                step_type_id: "145",
                step_type_name: "Keyword",
            },
            107: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "107",
                step_type_name: "Events",
                qualifier: "1",
                qualifier_name: "Include Any",
            },
            108: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "108",
                step_type_name: "Event search",
                qualifier: "1",
                qualifier_name: "Include Any",
            },
            192: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "192",
                step_type_name: "Event search (exclude)",
                qualifier: "1",
                qualifier_name: "Include Any",
            },
            104: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "104",
                step_type_name: "Event Folders",
                qualifier: "1",
                qualifier_name: "Include Any",
            },
            105: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "105",
                step_type_name: "Event Cabinets",
                qualifier: "1",
                qualifier_name: "Include Any",
            },
            102: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "102",
                step_type_name: "Event Types",
                qualifier: "1",
                qualifier_name: "Include Any",
            },
            103: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "103",
                step_type_name: "Event Types",
                qualifier: "-1",
                qualifier_name: "Do Not Include",
            },
            101: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "101",
                step_type_name: "Event State",
                qualifier: "1",
                qualifier_name: "Include Any",
            },
            110: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "110",
                step_type_name: "Organizations",
                qualifier: "1",
                qualifier_name: "Include Any",
            },
            112: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "112",
                step_type_name: "Organizations",
                qualifier: "-1",
                qualifier_name: "Do Not Include",
            },
            113: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "113",
                step_type_name: "Sponsoring organizations",
            },
            111: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "111",
                step_type_name: "Event Contacts",
                qualifier: "1",
                qualifier_name: "Include Any",
                contact_role_id: 0,
            },
            120: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "120",
                step_type_name: "Event Categories",
                qualifier: "1",
                qualifier_name: "Include Any",
            },
            121: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "121",
                step_type_name: "Event Categories",
                qualifier: "-1",
                qualifier_name: "Do Not Include",
            },
            130: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "130",
                step_type_name: "Event Requirements",
                qualifier: "1",
                qualifier_name: "Include Any",
            },
            131: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "131",
                step_type_name: "Event Requirements",
                qualifier: "-1",
                qualifier_name: "Do Not Include",
            },
            150: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "150",
                step_type_name: "Custom Attributes",
            },
            140: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [
                    {
                        from_dt: 0,
                        until_dt: 0,
                        occurrence_start_dt: (function () {
                            var d = new Date();
                            d.setHours(0);
                            d.setMinutes(0);
                            return d;
                        })(),
                        occurrence_end_dt: (function () {
                            var d = new Date();
                            d.setHours(23);
                            d.setMinutes(59);
                            return d;
                        })(),
                        monday: "T",
                        tuesday: "T",
                        wednesday: "T",
                        thursday: "T",
                        friday: "T",
                        saturday: "T",
                        sunday: "T",
                    },
                ],
                step_type_id: "140",
                step_type_name: "Occurrences",
                qualifier: "1",
                qualifier_name: "Include Any",
            },
            141: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [
                    {
                        expected_head_count_gte: " ",
                        expected_head_count_lte: " ",
                        registered_head_count_gte: " ",
                        registered_head_count_lte: " ",
                        reservation_name: " ",
                    },
                ],
                step_type_id: "141",
                step_type_name: "Reservation definition details",
            },
            143: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [
                    {
                        created_on_or_after: "",
                        start_time: (function () {
                            var d = new Date();
                            d.setHours(0);
                            d.setMinutes(0);
                            d.setSeconds(0);
                            return d;
                        })(),
                        from_dt: 0,
                    },
                ],
                step_type_id: "143",
                step_type_name: "CreateDt",
            },
            146: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ profile_code: "" }],
                step_type_id: "146",
                step_type_name: "Profile Code",
                qualifier: 1,
                qualifier_name: "Include Any",
            },
            147: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ profile_code: "" }],
                step_type_id: "147",
                step_type_name: "Profile Code",
                qualifier: -1,
                qualifier_name: "Include None",
            },
            148: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "148",
                step_type_name: "Standard Schedule",
                qualifier: 1,
                qualifier_name: "Include Any",
            },
            149: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "149",
                step_type_name: "Standard Schedule",
                qualifier: -1,
                qualifier_name: "Include None",
            },
            163: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "163",
                step_type_name: "Assigned Spaces",
                qualifier: "1",
                qualifier_name: "Include Any",
            },
            165: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "165",
                step_type_name: "Space Preferences",
                qualifier: "1",
                qualifier_name: "Include Any",
            },
            162: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "162",
                step_type_name: "Space assignments",
            },
            160: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ from_dt: 0, until_dt: 1 }],
                step_type_id: "160",
                step_type_name: "Missing space assignments",
            },
            168: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "168",
                step_type_name: "Assigned Resources",
                qualifier: "1",
                qualifier_name: "Include Any",
            },
            167: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "167",
                step_type_name: "Resource assignments",
            },
            174: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ outstanding_vcal_to_dos: "T" }],
                step_type_id: "174",
                step_type_name: "Outstanding Needs Space vCalendar To Dos",
            },
            175: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ outstanding_vcal_to_dos: "T" }],
                step_type_id: "175",
                step_type_name: "Outstanding Exceeds Headcount vCalendar To Dos",
            },
            176: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ outstanding_vcal_to_dos: "T" }],
                step_type_id: "176",
                step_type_name: "Outstanding Export vCalendar To Dos",
                qualifier: "1",
                qualifier_name: "Include Any",
            },
            185: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "185",
                step_type_name: "Attendees",
                qualifier: 1,
                qualifier_name: "Include Any",
            },
            184: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ has_invoice: 1 }],
                step_type_id: "184",
                step_type_name: "Has Invoices",
            },
            183: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ has_outstanding_payments: 1 }],
                step_type_id: "183",
                step_type_name: "Has Outstanding Payments",
            },
            20: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ related_event_type_id: -1 }],
                step_type_id: "20",
                step_type_name: "Include related events",
                qualifier: "1",
                qualifier_name: "Include Any",
            },

            subType: {
                100: {
                    1: {
                        itemName: " ",
                        event_title: false,
                        reference_number: false,
                        event_structure_element_type: false,
                        start_dt: false,
                        end_dt: false,
                        modified_since: false,
                        last_modified_by: false,
                        alien_uid: false,
                    },
                    2: {
                        itemName: false,
                        event_title: " ",
                        reference_number: false,
                        event_structure_element_type: false,
                        start_dt: false,
                        end_dt: false,
                        modified_since: false,
                        last_modified_by: false,
                        alien_uid: false,
                        searchMethod: "contains",
                    },
                    3: {
                        itemName: false,
                        event_title: false,
                        reference_number: " ",
                        event_structure_element_type: false,
                        start_dt: false,
                        end_dt: false,
                        modified_since: false,
                        last_modified_by: false,
                        alien_uid: false,
                        searchMethod: false,
                    },
                    4: {
                        itemName: false,
                        event_title: false,
                        reference_number: false,
                        event_structure_element_type: " ",
                        start_dt: false,
                        end_dt: false,
                        modified_since: false,
                        last_modified_by: false,
                        alien_uid: false,
                        searchMethod: false,
                    },
                    5: {
                        itemName: false,
                        event_title: false,
                        reference_number: false,
                        event_structure_element_type: false,
                        start_dt: new Date(),
                        end_dt: false,
                        modified_since: false,
                        last_modified_by: false,
                        alien_uid: false,
                        searchMethod: false,
                    },
                    6: {
                        itemName: false,
                        event_title: false,
                        reference_number: false,
                        event_structure_element_type: false,
                        start_dt: false,
                        end_dt: new Date(),
                        modified_since: false,
                        last_modified_by: false,
                        alien_uid: false,
                        searchMethod: false,
                    },
                    7: {
                        itemName: false,
                        event_title: false,
                        reference_number: false,
                        event_structure_element_type: false,
                        start_dt: false,
                        end_dt: false,
                        modified_since: new Date(),
                        last_modified_by: false,
                        alien_uid: false,
                        searchMethod: false,
                    },
                    8: {
                        itemName: false,
                        event_title: false,
                        reference_number: false,
                        event_structure_element_type: false,
                        start_dt: false,
                        end_dt: false,
                        modified_since: false,
                        last_modified_by: " ",
                        alien_uid: false,
                        searchMethod: false,
                    },
                    9: {
                        itemName: false,
                        event_title: false,
                        reference_number: false,
                        event_structure_element_type: false,
                        start_dt: false,
                        end_dt: false,
                        modified_since: false,
                        last_modified_by: false,
                        alien_uid: " ",
                        searchMethod: false,
                    },
                },

                141: {
                    1: {
                        expected_head_count_gte: " ",
                        expected_head_count_lte: " ",
                        registered_head_count_gte: false,
                        registered_head_count_lte: false,
                        reservation_name: false,
                    },
                    2: {
                        expected_head_count_gte: false,
                        expected_head_count_lte: false,
                        registered_head_count_gte: " ",
                        registered_head_count_lte: " ",
                        reservation_name: false,
                    },
                    3: {
                        expected_head_count_gte: false,
                        expected_head_count_lte: false,
                        registered_head_count_gte: false,
                        registered_head_count_lte: false,
                        reservation_name: " ",
                    },
                },

                20: {
                    1: {
                        related_event_type_id: 11,
                        related_event_type_name: "Content",
                    },
                    2: {
                        related_event_type_id: 12,
                        related_event_type_name: "Binding Reservation",
                    },
                },
            },

            searchDefaults: {
                100: {
                    start_dt: (date: any) => date && (null as Date),
                    end_dt: (date: any) => date && (null as Date),
                    modified_since: (date: any) => date && (null as Date),
                },
            },

            preDefaults: {
                100: {
                    start_dt: function () {
                        return new Date();
                    },
                    end_dt: function () {
                        var dt = new Date();
                        dt.setDate(dt.getDate() + 1);
                        return dt;
                    },
                    modified_since: function () {
                        return new Date();
                    },
                },

                140: {
                    occurrence_start_dt: function () {
                        var d = new Date();
                        d.setHours(0);
                        d.setMinutes(0);
                        return d;
                    },
                    occurrence_end_dt: function () {
                        var d = new Date();
                        d.setHours(23);
                        d.setMinutes(59);
                        return d;
                    },
                },
            },
            postDefaults: {
                100: {
                    itemName: "",
                    event_title: "",
                    reference_number: "",
                    event_structure_element_type: "",
                    last_modified_by: "",
                    alien_uid: "",
                },

                141: {
                    reservation_name: "",
                },
            },

            validation: {
                100: validateAnyField,
                101: validateAnyItem,
                102: validateAnyItem,
                104: validateAnyItem,
                105: validateAnyItem,
                107: validateAnyItem,
                108: validateAnyItem,
                110: validateAnyItem,
                111: validateAnyItem,
                113: validateAnyItem,
                120: validateAnyItem,
                130: validateAnyItem,
                141: (step: Step) => {
                    if (step.sub_step_type_id === 3) return validateSingleField("reservation_name", step);
                    return validateAnyField(step);
                },
                145: validateSingleField.bind(null, "itemName"),
                150: validateCustomAttributes,
                162: validateAnyItem,
                163: validateAnyItem,
                165: validateAnyItem,
                167: validateAnyItem,
                168: validateAnyItem,
                192: validateAnyItem,
            },
        },

        location: {
            10: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ favorites: "T" }],
                step_type_id: "10",
                step_type_name: "My favorite spaces",
            },
            400: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [
                    {
                        itemName: " ",
                        formal_name: " ",
                        max_capacity: " ",
                        min_capacity: " ",
                        fill_ratio: " ",
                        modified_since: new Date(),
                        last_modified_by: " ",
                        searchMethod: "is",
                    },
                ],
                step_type_id: "400",
                step_type_name: "General Space Details",
            },
            445: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ itemName: "" }],
                step_type_id: "445",
                step_type_name: "Keyword",
            },
            405: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "405",
                step_type_name: "Space search",
            },
            407: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "407",
                step_type_name: "Spaces",
            },
            425: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "425",
                step_type_name: "Buildings",
            },
            426: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "425",
                step_type_name: "Buildings Exclude",
            },
            423: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "423",
                step_type_name: "Partitions",
            },
            424: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "424",
                step_type_name: "Partitions",
            },
            410: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "410",
                step_type_name: "Features",
            },
            411: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "411",
                step_type_name: "Features",
            },
            420: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "420",
                step_type_name: "Categories",
            },
            421: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "421",
                step_type_name: "Categories",
            },
            430: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "430",
                step_type_name: "Layouts",
            },
            433: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "433",
                step_type_name: "Layouts",
            },
            460: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ relationship_type_id: 1 }],
                step_type_id: "460",
                step_type_name: "Space Relationships",
                qualifier: 1,
            },
            461: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ relationship_type_id: 1 }],
                step_type_id: "461",
                step_type_name: "Space Relationships",
                qualifier: -1,
            },
            450: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "450",
                step_type_name: "Custom Attributes",
            },
            470: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "470",
                step_type_name: "Space assignments", //event search
            },
            471: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "471",
                step_type_name: "Assigned Spaces", //specific events
            },
            20: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "20",
                step_type_name: "Include related spaces",
            },

            subType: {
                400: {
                    1: {
                        itemName: " ",
                        formal_name: false,
                        max_capacity: false,
                        min_capacity: false,
                        fill_ratio: false,
                        modified_since: false,
                        last_modified_by: false,
                    },
                    2: {
                        itemName: false,
                        formal_name: " ",
                        max_capacity: false,
                        min_capacity: false,
                        fill_ratio: false,
                        modified_since: false,
                        last_modified_by: false,
                        searchMethod: "contains",
                    },
                    3: {
                        itemName: false,
                        formal_name: false,
                        max_capacity: " ",
                        min_capacity: false,
                        fill_ratio: false,
                        modified_since: false,
                        last_modified_by: false,
                        searchMethod: false,
                    },
                    4: {
                        itemName: false,
                        formal_name: false,
                        max_capacity: false,
                        min_capacity: " ",
                        fill_ratio: false,
                        modified_since: false,
                        last_modified_by: false,
                        searchMethod: false,
                    },
                    5: {
                        itemName: false,
                        formal_name: false,
                        max_capacity: false,
                        min_capacity: false,
                        fill_ratio: " ",
                        modified_since: false,
                        last_modified_by: false,
                        searchMethod: false,
                    },
                    6: {
                        itemName: false,
                        formal_name: false,
                        max_capacity: false,
                        min_capacity: false,
                        fill_ratio: false,
                        modified_since: new Date(),
                        last_modified_by: false,
                        searchMethod: false,
                    },
                    7: {
                        itemName: false,
                        formal_name: false,
                        max_capacity: false,
                        min_capacity: false,
                        fill_ratio: false,
                        modified_since: false,
                        last_modified_by: " ",
                        searchMethod: false,
                    },
                    8: {
                        itemName: false,
                        formal_name: false,
                        max_capacity: " ",
                        min_capacity: " ",
                        fill_ratio: false,
                        modified_since: false,
                        last_modified_by: false,
                        searchMethod: false,
                    },
                },
            },

            searchDefaults: {
                400: {
                    modified_since: (date: any) => date && (null as Date),
                },
            },

            preDefaults: {
                400: {
                    modified_since: function () {
                        return new Date();
                    },
                },
            },
            postDefaults: {
                400: {
                    itemName: "",
                    formal_name: "",
                    max_capacity: "",
                    min_capacity: "",
                    fill_ratio: "",
                    last_modified_by: "",
                },
            },

            validation: {
                20: validateAnyItem,
                400: validateAnyField,
                405: validateAnyItem,
                407: validateAnyItem,
                410: validateAnyItem,
                420: validateAnyItem,
                423: validateAnyItem,
                425: validateAnyItem,
                430: validateAnyItem,
                445: validateSingleField.bind(null, "itemName"),
                450: validateCustomAttributes,
                460: validateAnyItem,
                470: validateAnyItem,
                471: validateAnyItem,
            },
        },

        organization: {
            10: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ favorites: "T" }],
                step_type_id: "10",
                step_type_name: "My favorite organizations",
            },
            200: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [
                    {
                        itemName: " ",
                        formal_name: " ",
                        s25_keys: " ",
                        accounting_code: " ",
                        modified_since: new Date(),
                        last_modified_by: " ",
                        searchMethod: "is",
                    },
                ],
                step_type_id: "200",
                step_type_name: "General Organization Details",
            },
            245: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ itemName: "" }],
                step_type_id: "245",
                step_type_name: "Keyword",
            },
            205: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "205",
                step_type_name: "Organization search",
            },
            207: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "207",
                step_type_name: "Organizations",
            },
            240: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ address_type_id: 1 }],
                step_type_id: "240",
                step_type_name: "Address",
            },
            220: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "220",
                step_type_name: "Categories",
            },
            221: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "221",
                step_type_name: "Categories",
            },
            210: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "210",
                step_type_name: "Organization Contacts",
                contact_role_id: 0,
            },
            230: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "230",
                step_type_name: "Organization Types",
            },
            231: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "231",
                step_type_name: "Organization Types",
            },
            250: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "250",
                step_type_name: "Custom Attributes",
            },
            280: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "280",
                step_type_name: "Organization Ratings",
            },
            281: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "281",
                step_type_name: "Organization Ratings",
            },
            270: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "270",
                step_type_name: "Organization assignments", //event search
            },
            271: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "271",
                step_type_name: "Assigned Organizations", //specific events
            },

            subType: {
                200: {
                    1: {
                        itemName: " ",
                        formal_name: false,
                        s25_keys: false,
                        accounting_code: false,
                        modified_since: false,
                        last_modified_by: false,
                    },
                    2: {
                        itemName: false,
                        formal_name: " ",
                        s25_keys: false,
                        accounting_code: false,
                        modified_since: false,
                        last_modified_by: false,
                        searchMethod: "contains",
                    },
                    3: {
                        itemName: false,
                        formal_name: false,
                        s25_keys: " ",
                        accounting_code: false,
                        modified_since: false,
                        last_modified_by: false,
                        searchMethod: false,
                    },
                    4: {
                        itemName: false,
                        formal_name: false,
                        s25_keys: false,
                        accounting_code: " ",
                        modified_since: false,
                        last_modified_by: false,
                        searchMethod: false,
                    },
                    5: {
                        itemName: false,
                        formal_name: false,
                        s25_keys: false,
                        accounting_code: false,
                        modified_since: new Date(),
                        last_modified_by: false,
                        searchMethod: false,
                    },
                    6: {
                        itemName: false,
                        formal_name: false,
                        s25_keys: false,
                        accounting_code: false,
                        modified_since: false,
                        last_modified_by: " ",
                        searchMethod: false,
                    },
                },
            },

            searchDefaults: {
                200: {
                    modified_since: (date: any) => date && (null as Date),
                },
            },

            preDefaults: {
                200: {
                    modified_since: function () {
                        return new Date();
                    },
                },
            },
            postDefaults: {
                200: {
                    itemName: "",
                    formal_name: "",
                    s25_keys: "",
                    accounting_code: "",
                    last_modified_by: "",
                },
            },

            validation: {
                200: validateAnyField,
                205: validateAnyItem,
                207: validateAnyItem,
                210: validateAnyItem,
                222: validateAnyItem,
                220: validateAnyItem,
                230: validateAnyItem,
                245: validateSingleField.bind(null, "itemName"),
                250: validateCustomAttributes,
                280: validateAnyItem,
                270: validateAnyItem,
                271: validateAnyItem,
            },
        },

        resource: {
            10: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ favorites: "T" }],
                step_type_id: "10",
                step_type_name: "My favorite resources",
            },
            600: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [
                    {
                        itemName: " ",
                        modified_since: new Date(),
                        last_modified_by: " ",
                        searchMethod: "is",
                    },
                ],
                step_type_id: "600",
                step_type_name: "General Resource Details",
            },
            645: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ itemName: "" }],
                step_type_id: "645",
                step_type_name: "Keyword",
            },
            605: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "605",
                step_type_name: "Resource search",
            },
            607: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "607",
                step_type_name: "Resources",
            },
            620: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "620",
                step_type_name: "Categories",
            },
            621: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "621",
                step_type_name: "Categories",
            },
            660: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ relationship_type_id: 8 }],
                step_type_id: "660",
                step_type_name: "Resource Relationships",
                qualifier: 1,
            },
            661: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [{ relationship_type_id: 8 }],
                step_type_id: "661",
                step_type_name: "Resource Relationships",
                qualifier: -1,
            },
            650: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "650",
                step_type_name: "Custom Attributes",
            },
            670: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "670",
                step_type_name: "Resource assignments", //event search
            },
            671: {
                prefix: "r25",
                status: "est",
                step_number: "1",
                step_param: [],
                step_type_id: "671",
                step_type_name: "Assigned Resources", //specific events
            },

            subType: {
                600: {
                    1: {
                        itemName: " ",
                        modified_since: false,
                        last_modified_by: false,
                    },
                    2: {
                        itemName: false,
                        modified_since: new Date(),
                        last_modified_by: false,
                        searchMethod: false,
                    },
                    3: {
                        itemName: false,
                        modified_since: false,
                        last_modified_by: " ",
                        searchMethod: false,
                    },
                },
            },

            searchDefaults: {
                600: {
                    modified_since: (date: any) => date && (null as Date),
                },
            },

            preDefaults: {
                600: {
                    modified_since: function () {
                        return new Date();
                    },
                },
            },
            postDefaults: {
                600: {
                    itemName: "",
                    last_modified_by: "",
                },
            },

            validation: {
                600: validateAnyField,
                605: validateAnyItem,
                607: validateAnyItem,
                620: validateAnyItem,
                645: validateSingleField.bind(null, "itemName"),
                650: validateCustomAttributes,
                660: validateAnyItem,
                670: validateAnyItem,
                671: validateAnyItem,
            },
        },

        task: {
            //this component is formed purely in template in s25-advanced-search-tmpl.js: s25-advanced-search-criteria-task.tmpl
            1000: {
                dateBean: [{ step_param: [{ from_dt: 0, until_dt: 1 }] }],
                states: { outstanding: true, completed: false, denied: false, cancelled: false, unread: false },
                types: { todo: true, vcal: true, authorization: true, fyi: true, assignment: true },
                contactBeans: [
                    {
                        title: "Assigned To",
                        step_type_id: 210,
                        step_param: [],
                    },
                    {
                        title: "Assigned From",
                        step_type_id: 210,
                        step_param: [],
                    },
                ],
            },
        },
    };

    public static getStep(itemTypeId: Item.Id, stepTypeId: SearchCriteria.StepTypeId, subStepTypeId?: number) {
        const template = S25Util.deepCopy(
            AdvancedSearchUtil.s25SearchAdvancedStepTemplate[S25Const.itemId2Name[itemTypeId]],
        );
        const step = template[stepTypeId];

        // Merge subtype template details into new step (hides fields we want hidden for some subtypes)
        if (subStepTypeId) {
            step.step_param = step.step_param.map((item: any) => ({
                ...item,
                ...template.subType[stepTypeId][subStepTypeId],
            }));
            step.step_param.map(S25Util.deleteFalseProperties); // Delete false sub items
        }
        step.step_param?.map((param: any) => {
            // Change placeholder " " into ""
            for (let [key, val] of Object.entries(param)) {
                if (typeof val === "string" && /^\s*$/.test(val)) param[key] = null;
            }
            // Some values that work for regular search criteria need to be replaced here
            if (template.searchDefaults?.[stepTypeId]) {
                for (let [key, func] of Object.entries(template.searchDefaults?.[stepTypeId]) as [string, Function][]) {
                    param[key] = func(param[key]);
                }
            }
        });

        return step;
    }
}

function validateAnyField(step: Step) {
    const ok = !!Object.entries(step.step_param[0]).find(([key, value]) => key !== "searchMethod" && value);
    if (!ok) return "Please fill out at least one field.";
}

function validateSingleField(field: string, step: Step) {
    const ok = S25Util.isDefined(step.step_param[0][field]) && step.step_param[0][field] !== "";
    if (!ok) return "Please fill out this field.";
}

function validateAnyItem(step: Step) {
    const ok = !!step.step_param.length;
    if (!ok) return "Please select at least one item.";
}

function validateCustomAttributes(step: Step) {
    let ok = !!step.step_param.length;
    if (!ok) return "Please select at least one item.";

    if ([56, 57].includes(Number(step.step_type_id) % 100)) return; // Exists / Does Not Exist

    ok = S25Util.isDefined(step.step_param[0].itemId);
    if (!ok) return "Please select a value.";
}
