import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { MasqueradeService } from "../../services/masquerade.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { AuthService } from "../../services/auth.service";

@TypeManagerDecorator("s25-ng-masquerade")
@Component({
    selector: "s25-ng-masquerade",
    template: `
        @if (isInit) {
            <div>
                @if (!mapping) {
                    <div class="system-settings--wrapper">
                        <h2 class="c-margin-bottom--single">Masquerade</h2>
                        <s25-loading-inline [model]="{}"></s25-loading-inline>
                        <span class="c-margin-bottom--half ngBold">Select a User</span>
                        <div>
                            <s25-contact-dropdown
                                [r25UserOnly]="true"
                                [(chosen)]="chosenContact"
                                [onLoad]="contactsLoaded"
                                class="c-margin-bottom--half"
                            ></s25-contact-dropdown>
                            <button class="aw-button aw-button--primary c-margin-top--half" (click)="masquerade()">
                                Masquerade as User
                            </button>
                        </div>
                    </div>
                }
                @if (mapping) {
                    <div>
                        <h2 class="c-margin-bottom--single">Masquerade Mapping</h2>
                        <s25-ng-masquerade-list> </s25-ng-masquerade-list>
                    </div>
                }
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25MasqueradeComponent implements OnInit {
    chosenContact: any;
    proInstance: string = window.ProData.instanceId;
    isInit = false;
    @Input() mapping?: any;

    data: any;

    constructor(private elementRef: ElementRef) {}

    ngOnInit() {
        S25LoadingApi.init(this.elementRef.nativeElement);
        this.isInit = true;
    }

    contactsLoaded = () => S25LoadingApi.destroy(this.elementRef.nativeElement);

    masquerade() {
        if (this.chosenContact) {
            S25LoadingApi.init(this.elementRef.nativeElement);
            return MasqueradeService.masquerade(this.chosenContact.itemId).then(
                () => {
                    S25Util.setCookie(`S25Masquerade_${this.proInstance}`, true);
                    return AuthService.masqueradeLogin();
                },
                (error: any) => {
                    S25LoadingApi.destroy(this.elementRef.nativeElement);
                    S25Util.showError(error);
                },
            );
        } else {
            alert("Please choose a contact");
        }
    }
}
