import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnInit } from "@angular/core";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { MasqueradeService } from "../../services/masquerade.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { ModalService } from "../modal/modal.service";
import { ContactService } from "../../services/contact.service";
import { AuthService } from "../../services/auth.service";
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { Masquerade } from "../../pojo/Masquerade";

@TypeManagerDecorator("s25-ng-masquerade-switch-roles")
@Component({
    selector: "s25-ng-masquerade-switch-roles",
    template: `
        @if (isInit) {
            <div>
                @if (!getRoles || getRoles.length === 0) {
                    <div class="ngAnchor">None</div>
                }
                @if (isModal) {
                    <div>
                        @if (getRoles && getRoles.length > 0) {
                            <div>
                                @for (r of getRoles; track r; let i = $index) {
                                    <div>
                                        @if (i < 10) {
                                            <span class="ngAnchor" (click)="masquerade(r)">
                                                {{ r.targetFirstName }} {{ r.targetLastName }}
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        }
                        @if (getRoles && getRoles.length > 10) {
                            <div class="more-button">
                                <button (click)="onClickShowMore()" class="aw-button aw-button--outline">More</button>
                            </div>
                        }
                    </div>
                }
                @if (!isModal) {
                    <div>
                        @if (getRoles && getRoles.length > 0) {
                            <div>
                                @for (r of getRoles; track r) {
                                    <div>
                                        <span class="ngAnchor" (click)="masquerade(r)">
                                            {{ r.targetFirstName }} {{ r.targetLastName }}
                                        </span>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                }
            </div>
        }
    `,
    styles: `
        .top {
            max-width: 1200px;
        }

        .button-group {
            display: inline-block;
            gap: 0.5em;
            margin: 0.5em 0;
        }

        .more-button {
            margin: 0.5em 0.5em;
        }
    `,
})
export class S25MasqueradeSwitchRolesComponent implements OnInit {
    isInit: boolean = false;
    getRoles: any = [];
    groupId: any;
    currentUsername: string;
    getCurrentId: number;

    @Input() isModal?: boolean = false;

    showAddButton?: boolean = true;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {}

    async ngOnInit() {
        this.getCurrentId = await ContactService.getCurrentId();
        this.getRoles = await MasqueradeService.getMasqueradeUsers(this.getCurrentId);
        this.getRoles = this.getRoles.filter(
            (i: any) =>
                S25Datefilter.transform(i.expirationDt, "yyyy-MM-dd") >
                    S25Datefilter.transform(new Date(), "yyyy-MM-dd") || i.expirationDt === "1900-01-01T00:00:00",
        );

        this.getRoles = S25Util.array.forceArray(this.getRoles);
        this.isInit = true;
        this.cd.detectChanges();
    }

    masquerade(target: Masquerade) {
        let proInstance: string = window.ProData.instanceId;
        S25LoadingApi.init(this.elementRef.nativeElement);
        return MasqueradeService.masqueradeByUsername(target.userName).then(
            () => {
                S25Util.setCookie(`S25Masquerade_${proInstance}`, true);
                return AuthService.masqueradeLogin();
            },
            (error: any) => {
                S25LoadingApi.destroy(this.elementRef.nativeElement);
                S25Util.showError(error);
            },
        );
    }

    error(error: any) {
        S25Util.showError(error);
    }

    onClickShowMore() {
        ModalService.modal("switch-roles", {
            items: this.getRoles,
            title: `switch-roles`,
            onMasquerade: this.masquerade,
        });
    }
}
