import "zone.js"; // required for NgZone and automatic change detection
import "@angular/localize/init"; // required for ngb (datepickers, etc)
import * as Sentry from "@sentry/angular";
import { AngBridge } from "./ang.bridge";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app.module";
import { S25Util } from "../util/s25-util";

declare global {
    interface Window {
        unitTesting: boolean;
    }
}

export class Main {
    public static init() {
        if (!window.unitTesting) {
            const version = window.ProData?.version
                ? new Date(parseInt(window.ProData.version)).toISOString()
                : "unknown";
            Sentry.init({
                release: `25live@${version}`,
                tracePropagationTargets: [
                    "localhost",
                    "gauss.unival.com",
                    /^https:\/\/25live\.collegenet\.com\/25live\/data/,
                ],
                dsn: "https://625689fee347e3cb041149c5d68bb169@o4506622590124032.ingest.sentry.io/4506622593138688",
                integrations: [Sentry.browserTracingIntegration()],
                // Performance Monitoring
                tracesSampleRate: 1.0, //  Capture 100% of the transactions

                beforeSend(event) {
                    if (window.unitTesting) {
                        return null;
                    } else {
                        if (S25Util.isAppleMail()) {
                            return null;
                        }
                        return event;
                    }
                },

                // strings will match any partial match
                // you can also use regex
                ignoreErrors: [
                    "TransitionRejection",
                    "transition has been superseded",
                    "No Permissions",
                    "NS_ERROR_UNEXPECTED",
                    "Event is locked", // Ignore locked events
                    /\w+ not found. Details: The \w+_id is not valid/, // Ignore invalid object pages
                ],
            });

            Sentry.setTag("instance", window.ProData?.instanceId);
            Sentry.setTag("dev", !!window.ProData?.isDevMode);
            Sentry.setTag("embedded", !!window.ProData?.embedded);
            if (window.ProData?.embedded) {
                Sentry.setContext("embedded", {
                    config: window.ProData?.embeddedConfigToken,
                    type: window.ProData?.embeddedType,
                });
            }
        }

        AngBridge.createAngBridge();

        platformBrowserDynamic().bootstrapModule(AppModule);
    }
}
