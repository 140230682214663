import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { S25MasqueradeComponent } from "./masquerade.component";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25ChartsModule } from "../s25-charts/s25.charts.module";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25MasqueradeListComponent } from "./masquerade.list.component";
import { S25TableModule } from "../s25-table/s25.table.module";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { S25MasqueradeMappingComponent } from "./masquerade.mapping.component";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25MasqueradeRolesComponent } from "./masquerade.roles.component";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { S25MasqueradeSwitchRolesComponent } from "./masquerade.switch.roles.component";
import { S25MasqueradeBulkEditComponent } from "./masquerade.bulk.edit.component";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";

@NgModule({
    declarations: [
        S25MasqueradeComponent,
        S25MasqueradeListComponent,
        S25MasqueradeMappingComponent,
        S25MasqueradeRolesComponent,
        S25MasqueradeSwitchRolesComponent,
        S25MasqueradeBulkEditComponent,
    ],
    imports: [
        CommonModule,
        S25LoadingInlineModule,
        S25DropdownPaginatedModule,
        S25ChartsModule,
        S25MultiselectModule,
        S25TableModule,
        S25ModalModule,
        S25EditableModule,
        S25ItemModule,
        S25ToggleButtonModule,
        S25CheckboxModule,
    ],
    providers: [
        S25MasqueradeComponent,
        S25MasqueradeListComponent,
        S25MasqueradeMappingComponent,
        S25MasqueradeRolesComponent,
        S25MasqueradeSwitchRolesComponent,
        S25MasqueradeBulkEditComponent,
    ],
    exports: [
        S25MasqueradeComponent,
        S25MasqueradeListComponent,
        S25MasqueradeMappingComponent,
        S25MasqueradeRolesComponent,
        S25MasqueradeSwitchRolesComponent,
        S25MasqueradeBulkEditComponent,
    ],
})
export class S25MasqueradeModule {
    constructor() {}
}
